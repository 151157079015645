#experience {
    background-color: $white-color;
    color: $dark-grey;
    height: 100%;
    border-top: 0px;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    padding-bottom: 10%;
    min-height: 800px;
}

.experience-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
}

.outer {
    height: 100vh;
    min-width: 20vw;
    width:100%;
  }

.exp-progress {
    $gap: 20px;
    $line-height: 100px;
    $bullet-radius: 8px;
    $line-thick: 2px;
    $strip-color: #333;
    $next-color: #666;
    $current-color: #333;
    
    $prev-color: #333;
    
    justify-content: center;
    height: 100%;
    min-height: 600px;
    font-size: 15px;
    background-color: $white-color;;
  
    .experience-detail {
      .experience-lines {
        color:$dark-blue-text;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .left,.right {
        width: 350px;
    }

    .right {
        text-align: left;
        padding-left: 10px;
    }

    .left {
        padding-right: 10px;
    }
    > div {
      display: flex;
      flex-direction: column;
      color: $prev-color;
  
      &.left {
        padding-right: $gap;
        text-align: right;
        // Line
        div {
          &:last-of-type:after {
            display: none;
          }
          
          &:after {
            content: "";
            background: $strip-color; //rgba(0, 0, 0, 0.6);
            border-radius: 2px;
            position: absolute;
            right: -$gap;
            top: $line-height/2;
            height: 101%;
            width: $line-thick;
            transform: translateX(50%);
          }
        }
      }
  
      &.right {
        padding-left: $gap;
  
        div {        
          &.prev {          
              &:after {
                transition: none;
              }
          }
          
          &.current {
            color: $current-color;
            font-weight: bold;
  
            &:before {
              background: $primary-color;
              padding: $bullet-radius * 2;
            }
  
            &:after {
              height: 0%;
              transition: height .2s ease-out;
            }
  
            ~ div {
              color: $current-color;
              
              &:before {
                background: $current-color;
                padding: $bullet-radius * 2;
              }
  
              &:after {
                height: 0%;
                transition: none;
              }
            }
          }
  
          // Dot
          &:before {
            content: "";
            background: $primary-color;
            padding: $bullet-radius * 2;
            border-radius: 50%;
            position: absolute;
            left: -$gap;
            top: $line-height/2;
            transform: translateX(-50%) translateY(-50%);
            transition: padding 0.2s ease;
          }
  
          // Line
          &:after {
            content: "";
            background: $primary-color; //rgba(0, 0, 0, 0.6);
            border-radius: 2px;
            position: absolute;
            left: -$gap;
            top: $line-height/2;
            height: 101%;
            width: $line-thick;
            transform: translateX(-50%);
            transition: height 0.2s ease;
          }
        }
      }
  
      div {
        flex: 1;
        //outline: 1px solid rgba(0, 0, 0, 0.1);
        position: relative;
        cursor: default;
        min-height: 30px;
      }
    }
}


  